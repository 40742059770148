import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I've created `}<em parentName="p">{`The Simple Crypto Fund`}</em>{` for two reasons:`}</p>
    <ol>
      <li parentName="ol">{`Help others to make investment decisions in this 'new' asset class. return
Translate the universal rules of investing into the crypto space. Crypto can feel intimidating and easy at the same time. This makes it difficult and potentially dangerous for new beginners and non-tech-savvy investors.`}</li>
      <li parentName="ol">{`To have a central hub for my personal crypto allocation.
The fund regularly publishes historical risk and returns, investment process.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      